import styled from 'styled-components';
import COLORS from 'constants/colors';
import MEDIA from 'helpers/mediaTemplates';

export const ListItem = styled.li`
  padding: 30px 0 5px;
  display: flex;
  min-height: 60px;
  align-items: flex-end;
  font-weight: bold;
  flex-wrap: wrap;
  color: ${({ textColor }) => {
    return COLORS[textColor];
  }};
  ${({ textStyle }) => {
    if (textStyle == 'bolder') {
      return `
        font-family: 'balboaplus-fill', sans-serif;
        font-size: 32px;
      `;
    }
  }};
  border-bottom-style: solid;
  border-bottom-color: ${({ colorUnderline }) => {
    return COLORS[colorUnderline];
  }};
  border-bottom-width: ${({ textStyle }) => {
    if (textStyle == 'bolder') {
      return '1px';
    } else {
      return '2px';
    }
  }};

  ${({ listType, colorCounter }) => {
    if (listType == 'ordered') {
      return `
      padding: 20px 0 0;
      counter-increment: listCounter;
      align-items: flex-start;
      
      &::before {
        content: counter(listCounter) ". ";
        font-size: 20px;
        font-weight: 700;
        font-family: 'Chivo', Helvetica, Arial, sans-serif;
        margin-right: 14px;
        color: ${COLORS[colorCounter]};
      }

      &:nth-child(-n+9)::before {
        content: "0" counter(listCounter) ". ";
      }
      `;
    }
  }};
`;

export const Container = styled.ul`
  ${({ listType }) => {
    if (listType == 'ordered') {
      return 'counter-reset: listCounter;';
    }
  }};
  display: flex;
  flex-direction: column;

  &.doubleColumn {
    ${MEDIA.MIN_TABLET`
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;  
      align-items: flex-start;
    `};

    ${MEDIA.MIN_DESKTOP`
      padding-bottom: 30px;
    `};

    ${ListItem} {
      align-items: flex-start;

      ${MEDIA.MIN_TABLET`
        width: calc(50% - 15px);
      `};
    }
  }
`;

export const SubTitle = styled.span`
  font-weight: normal;
  flex-basis: 100%;
  color: ${({ subTitleColor }) => {
    return COLORS[subTitleColor];
  }};
`;
