import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import MEDIA from 'helpers/mediaTemplates';
import Head from 'components/head';
import SectionMain from 'components/section-main';
import SectionMainHead from 'components/section-main-head';
import Title from 'components/title';
import CtaButton from 'components/ctabutton';
import List from 'components/list';
import {
  AsideWithList,
  AsideWithGraph,
} from 'components/section-main/aside.css';
import COLORS from 'constants/colors';
import PatternTriangle4 from 'images/pattern-triangle4-gradient.svg';
import PatternTriangle4Inline from 'images/pattern-triangle4-inline.svg';
import PatternTriangle from 'images/pattern-triangle-iso.svg';
import PatternCircual from 'images/pattern-circual.svg';
import PatternMultident from 'images/pattern-multident.svg';
import PatternRound from 'images/pattern-round.svg';

export const IconWrapper = styled.span`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;

  ${MEDIA.MIN_TABLET`
    flex-wrap: no-wrap;
  `};

  a {
    width: 100%;

    &:last-child {
      margin-top: 15px;
    }

    ${MEDIA.MIN_TABLET`
      width: calc(50% - 10px);

      &:last-child {
        margin-top: 0;
      }
    `};
  }
`;

const Corsi = ({ data }) => {
  const PAGE = data.corsiJson;
  const CLASSICA = data.corsiJson.sections[0];
  const MODERNA = data.corsiJson.sections[1];
  const COMPLEMENTARI = data.corsiJson.sections[2];

  return (
    <>
      <Head
        pageTitle={PAGE.title}
        imageUrl={PAGE.socialImage.childImageSharp.fixed.src}
      />

      <SectionMainHead data={PAGE} />

      <SectionMain>
        <Title as="h3" size="lowercase" color="blue">
          {CLASSICA.subTitle}
        </Title>

        <Title as="h2" color="green" id="classica">
          {CLASSICA.title}
        </Title>

        <div
          className="offsetRight-tablet"
          dangerouslySetInnerHTML={{
            __html: CLASSICA.content.childMarkdownRemark.html,
          }}
        />

        <List list={CLASSICA.list} />

        <IconWrapper>
          <CtaButton linkTo="/contatti" fontWeight="bold" style="primary-green">
            {CLASSICA.cta}
          </CtaButton>

          <CtaButton
            linkTo="/gallery"
            fontWeight="bold"
            style="rounded"
            icon="photo"
          >
            {CLASSICA.ctasecondary}
          </CtaButton>
        </IconWrapper>

        <AsideWithGraph theme={{ align: 'right' }}>
          <PatternTriangle4 />
          <Img fluid={CLASSICA.aside.image.childImageSharp.fluid} />
          <PatternMultident />
          <PatternTriangle fill={COLORS.lightBlue} />
        </AsideWithGraph>
      </SectionMain>

      <SectionMain
        theme={{
          asideAlign: 'left',
        }}
      >
        <Title as="h3" size="lowercase" color="blue">
          {MODERNA.subTitle}
        </Title>

        <Title as="h2" color="green" id="moderna">
          {MODERNA.title}
        </Title>

        <div
          className="offsetRight-tablet"
          dangerouslySetInnerHTML={{
            __html: MODERNA.content.childMarkdownRemark.html,
          }}
        />

        <List list={MODERNA.list} />

        <IconWrapper>
          <CtaButton linkTo="/contatti" fontWeight="bold" style="primary-green">
            {MODERNA.cta}
          </CtaButton>

          <CtaButton
            linkTo="/gallery"
            fontWeight="bold"
            style="rounded"
            icon="photo"
          >
            {MODERNA.ctasecondary}
          </CtaButton>
        </IconWrapper>

        <AsideWithGraph theme={{ align: 'left' }}>
          <PatternCircual fill={COLORS.pink} />
          <Img fluid={MODERNA.aside.image.childImageSharp.fluid} />
          <PatternTriangle4Inline fill={COLORS.lightBlue} />
          <PatternTriangle fill={COLORS.pink} />
        </AsideWithGraph>
      </SectionMain>

      <SectionMain theme={{ verticalAlignment: 'center' }} bgColor="green">
        <Title as="h2" color="blue">
          {COMPLEMENTARI.title}
        </Title>

        <div
          className="offsetRight"
          color="blue"
          dangerouslySetInnerHTML={{
            __html: COMPLEMENTARI.content.childMarkdownRemark.html,
          }}
        />

        <AsideWithList>
          <List
            list={COMPLEMENTARI.list}
            listType="ordered"
            textColor="white"
            colorUnderline="white"
            textStyle="bolder"
          />

          <PatternCircual fill={COLORS.pink} />
          <Img fluid={COMPLEMENTARI.aside.image.childImageSharp.fluid} />
          <PatternMultident />
          <PatternRound />
        </AsideWithList>
      </SectionMain>
    </>
  );
};

Corsi.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Corsi;

export const query = graphql`
  query {
    corsiJson {
      title
      socialImage {
        childImageSharp {
          fixed(width: 1025, height: 512, cropFocus: CENTER) {
            src
          }
        }
      }
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      sections {
        title
        subTitle
        cta
        ctasecondary
        content {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        aside {
          image {
            childImageSharp {
              fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        list
      }
    }
  }
`;
