import React from 'react';
import PropTypes from 'prop-types';
import { Container, ListItem, SubTitle } from './list.css';

const List = ({
  list,
  className,
  listType = 'unordered',
  textColor = 'blue',
  textStyle = 'normal',
  colorUnderline = 'darkGreen',
  colorCounter = 'darkGreen',
  subTitleColor = 'white',
}) => {
  let listItems = list.map((item, _index) => {
    let itemContent = '';
    if (typeof item == 'string') {
      itemContent = item;
    } else if (typeof item == 'object' && item.title) {
      itemContent = item.title;
    }

    let subContent = '';
    if (typeof item == 'object' && item.subTitle) {
      subContent = (
        <SubTitle
          subTitleColor={subTitleColor}
          dangerouslySetInnerHTML={{
            __html: item.subTitle,
          }}
        />
      );
    }

    return (
      <ListItem
        key={_index}
        listType={listType}
        textColor={textColor}
        textStyle={textStyle}
        colorUnderline={colorUnderline}
        colorCounter={colorCounter}
      >
        {itemContent}
        {subContent}
      </ListItem>
    );
  });

  return <Container className={className}>{listItems}</Container>;
};

List.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array.isRequired,
  listType: PropTypes.oneOf(['unordered', 'ordered']),
  textColor: PropTypes.oneOf(['blue', 'white']),
  textStyle: PropTypes.oneOf(['normal', 'bolder']),
  colorUnderline: PropTypes.oneOf(['darkGreen', 'white']),
  colorCounter: PropTypes.oneOf(['darkGreen', 'white']),
  subTitleColor: PropTypes.oneOf(['blue', 'white', 'ultradarkGray']),
};

export default List;
